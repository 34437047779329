import path from 'path';

import { GetServerSideProps, NextPage } from 'next';
import dynamic from 'next/dynamic';

import { cacheControl } from '@hultafors/shared/helpers';

import { getApi } from '@hultafors/wibe/api';
import { createMeta } from '@hultafors/wibe/helpers';
import {
  GlobalFields,
  LandingPageFragment,
  PageProps,
} from '@hultafors/wibe/types';

// Needed for standalone build, for some reason
path.resolve('./next.config.js');

const CategoryGrid = dynamic(() =>
  import('@hultafors/wibe/components').then((module) => module.CategoryGrid),
);
const ContentArea = dynamic(() =>
  import('@hultafors/wibe/components').then((module) => module.ContentArea),
);
const BigPlug = dynamic(() =>
  import('@hultafors/wibe/components').then((module) => module.BigPlug),
);
const ColorPlugs = dynamic(() =>
  import('@hultafors/wibe/components').then((module) => module.ColorPlugs),
);
const Hero = dynamic(() =>
  import('@hultafors/wibe/components').then((module) => module.Hero),
);
const Page = dynamic(() =>
  import('@hultafors/wibe/components').then((module) => module.Page),
);
const PagePlugs = dynamic(() =>
  import('@hultafors/wibe/components').then((module) => module.PagePlugs),
);
const SimpleTextAndCta = dynamic(() =>
  import('@hultafors/wibe/components').then(
    (module) => module.SimpleTextAndCta,
  ),
);
const TitleAndTextBlock = dynamic(() =>
  import('@hultafors/wibe/components').then(
    (module) => module.TitleAndTextBlock,
  ),
);
const VisibleSeoBlock = dynamic(() =>
  import('@hultafors/wibe/components').then((module) => module.VisibleSeoBlock),
);

interface LandingPageProps extends PageProps {
  content: LandingPageFragment;
}

const LandingPage: NextPage<LandingPageProps> = ({ content, settings }) => {
  return (
    <Page
      metadata={createMeta(content.seo, settings?.language.lang)}
      testId="landing-page"
    >
      {content?.contentArea && <ContentArea content={content.contentArea} />}
    </Page>
  );
};

export const getServerSideProps: GetServerSideProps<LandingPageProps> = async ({
  res,
  req,
  locale,
}) => {
  try {
    const { settings, dato } = getApi(req, locale);

    const {
      data: { landingPage: content, ...global },
    } = await dato.getLandingPage();

    res.setHeader('Cache-Control', cacheControl.page);
    return {
      props: {
        content,
        settings,
        ...(global as GlobalFields),
      },
    };
  } catch (error) {
    return { notFound: true };
  }
};

export default LandingPage;
